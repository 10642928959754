<template>
  <div class="main-view">
    <div class="section filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="small">
        <el-form-item prop="account">
          <el-input v-model.trim="table.params.account" placeholder="反馈账号">
            <i slot="suffix" class="el-input__icon el-icon-search" @click="onSearch"></i>
          </el-input>
        </el-form-item>
        <el-form-item prop="source">
          <el-select v-model="table.params.source" placeholder="请选择反馈来源" @change="onSearch">
            <el-option label="家长" :value="1"></el-option>
            <el-option label="学校" :value="2"></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :tree-props="{children: 'children', hasChildren: 'has_child'}"
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:scoure="{row}">
        <span>{{row.scoure == 1 ? '家长' : '学校'}}</span>
      </template>
    </VTable>

  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'FeedbackList',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "scoure", label: "反馈来源", hidden: false },
        { name: "account", label: "反馈账号", hidden: false },
        { name: "content", label: "反馈内容", showTooltip: 'true', hidden: false},
        { name: "create_time", label: "反馈时间", hidden: false},
      ],
      table: {
        loading: false,
        params: {
          account: '',
          source: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
    }
  },
  created() {
    this.getTable();
  },
  methods: {
    // 查询列表
    getTable() {
      this.$http.get('/admin/feedback/list', {params: this.table.params}).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list
          this.table.total = res.data.total;
          // 重置查询条件
          this.$refs.elFormDom.resetFields()
          this.table.params.page = 1;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    
  }
}
</script>